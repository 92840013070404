        // creating heading elements
// 1. Select the div element using the id property
const insertHere = document.getElementById("insertHere");
// 2. Create a new h1 element 
const firstHeader = document.createElement("h1");
const aboutHeader = document.createElement("h2");
const progressHeader = document.createElement("h2");
const completedHeader = document.createElement("h2");
const funParagraph = document.createElement("p"); // used for ancestor
const funParagraph2 = document.createElement("p"); // used for ancestor / immediate siblings


// giving IDs to each heading elements
    firstHeader.setAttribute('id','header1');
    aboutHeader.setAttribute('att','aboutHeader');
    progressHeader.setAttribute('id','progHeader');
    completedHeader.setAttribute('id','comHeader');
    

// 3. Add the text content
firstHeader.textContent = "Using TypeScript and HTML";
aboutHeader.textContent = "About Me!";
progressHeader.textContent = "Courses in Progress";
completedHeader.textContent = "Completed Courses";
funParagraph.textContent = "Taught by the one and only Hans!";
funParagraph2.textContent = "Professor @ GVSU";

// 4. Append the p element to the div element
insertHere?.appendChild(firstHeader);
insertHere?.appendChild(funParagraph); // appends fun p
insertHere?.appendChild(funParagraph2); // appends fun p2
insertHere?.appendChild(aboutHeader);
insertHere?.appendChild(progressHeader);
insertHere?.appendChild(completedHeader);



// Creating personal information object
const personalInfo = {
    name: 'Brendan Smith',
    school: 'Grand Valley State University',
    year: '4',
   interests: ["Baseball ", " Disc Golf ", " Video Games"],
    graduatingOnTime: true,
}

// Creating the table that will be populated using the personalInfo object
const b = document.getElementsByName("aboutHeader");
const aboutTable = document.createElement("table");


aboutHeader?.appendChild(aboutTable);


//For in loop using about me object

const eLan = personalInfo as any;

for (let z in personalInfo){
    document.getElementsByName("aboutTable");
    let row = document.createElement("tr");
    let cellElement = document.createElement("td");
    
    
        row.textContent = z + ": ";
        cellElement.textContent = eLan[z];

    
    
        
    //console.log(typeof(eLan[z]));

    
            // nested for loop to list array elements
            const vLan = personalInfo.interests as any; // for array within personalInfo

            if (Array.isArray(eLan[z])) {
                cellElement.textContent = "";
                for (let x in personalInfo.interests) {
                
               

                
                document.getElementsByName("cellElement");
                let aboutUl = document.createElement("ul");
                let aboutLi = document.createElement("li");
                
                const liData = document.createTextNode(vLan[x]);
                

                cellElement?.appendChild(aboutUl);
                aboutUl?.appendChild(aboutLi);
                aboutLi.appendChild(liData);
                }
               
            }
            aboutTable?.appendChild(row);
            row?.appendChild(cellElement);

             // For in loop ends

    
        }
    

// defining the base type alias

type Course = { 
    code: string,
    name: string,
    credits: number
}

// Defining completed courses and ongoing courses
type CompletedCourse = Course & {
    semesterCompleted: string
}

type OngoingCourse = Course & {
    classTimes: Array<string>
}

// Defining arrays using the alias above

const onGoing: Array<OngoingCourse> = [
    {
        code: "CIS371",
        name: "Web Application Development",
        credits: 3,
        classTimes: ["Mon 2:00 - 2:50pm ", " Wed 2:00 - 2:50pm ", " Fri 2:00 - 2:50pm "]
    },
    {
        code: "CIS463",
        name: "Capstone",
        credits: 3,
        classTimes: ["Mon 3:00 - 3:50pm ", " Wed 3:00 - 3:50pm ", " Fri 3:00 - 3:50pm" ]
    },
    {
        code: "CIS375",
        name: "Wireless Systems Security",
        credits: 3,
        classTimes: ["Tuesday 3:00 - 3:50pm ", " Thursday 2:00 - 3:50pm"]
    },
    {
        code: "CIS437",
        name: "Distributed Computing",
        credits: 4,
        classTimes: ["Mon 10:00 - 10:50am ", " Wed 8:00 - 11:00am ", " Fri 10:00 - 10:50am"]
    },
]

const completed: Array<CompletedCourse> = [
    {
        code: "MTH201",
        name: "Calculus I",
        credits: 4,
        semesterCompleted: "W20"
    },
    {
        code: "COM201",
        name: "Speech",
        credits: 3,
        semesterCompleted: "W19"
    },
    {
        code: "CIS162",
        name: "Computer Science I",
        credits: 4,
        semesterCompleted: "F19"
    },
    {
        code: "STA215",
        name: "Intro Applied Statistics",
        credits: 3,
        semesterCompleted: "F19"
    },
    {
        code: "CIS260",
        name: "App Dev in Visual Basic",
        credits: 3,
        semesterCompleted: "W20"
    },
    {
        code: "MTH125",
        name: "Survey of Calculus",
        credits: 3,
        semesterCompleted: "W20"
    },
    {
        code: "ECO210",
        name: "Intro Macroeconomics",
        credits: 3,
        semesterCompleted: "W21"
    },
]






// getting h2 for ongoing classes

document.getElementById('progHeader'); // gets ongoing header
const ongoingTable = document.createElement("table"); // creates table
progressHeader?.appendChild(ongoingTable); //appends table

const cLan = onGoing as any; // for array within ongoing classes

let ongoingOl = document.createElement("ol");

for (const x of onGoing){
    document.getElementsByName("ongoingTable");
    let ongoingRow = document.createElement("tr");
    let ongoingCell = document.createElement("td");
    document.getElementsByName("ongoingCell");
    let ongoingLi = document.createElement("li");

    ongoingRow.textContent = x.code;
    
    const liData = document.createTextNode(x.code + "  "+ x.name + "  ("+ x.credits + " Credits)");

    ongoingTable?.appendChild(ongoingOl);
    ongoingOl?.appendChild(ongoingLi);
    ongoingLi?.appendChild(liData);


    const xLan = x.classTimes as any; 

        for (const z of onGoing){

        document.getElementsByName("ongoingLi"); // grabs current list
        let newUl2 = document.createElement("ul"); // creates a new ul
        let newLi2 = document.createElement("li"); //creates new li elements

        
        
        const li2Data = document.createTextNode(xLan)

        ongoingLi.appendChild(newUl2);
        newUl2.appendChild(newLi2);
        newLi2.appendChild(li2Data);

        }
        
    
}
   

    
    
    // COMPLETED CLASS IN TABLE
    

    document.getElementsByName("completedHeader"); //gets completed h2 section
    let comTable = document.createElement("table"); // creates our table
    completedHeader?.appendChild(comTable);

    let headingRow = document.createElement("tr");
    let headingCell1= document.createElement("td");
    let headingCell2= document.createElement("td");
    let headingCell3= document.createElement("td");
    //headingRow.textContent = "Course", "Credit", "Completed";
    headingCell1.textContent = "Course";  //Creates static table headings
    headingCell2.textContent = "Credits";
    headingCell3.textContent = "Completed";


    comTable?.appendChild(headingRow)
    headingRow?.appendChild(headingCell1); // appends our table headings
    headingRow?.appendChild(headingCell2);
    headingRow?.appendChild(headingCell3);


    const zLan = onGoing as any; // for array within ongoing classes

    for (const z of completed){
        document.getElementsByName("comTable"); // creates all elements
        let comRow = document.createElement("tr")
        let comCell = document.createElement("td")
        let comCell2 = document.createElement("td")
        let comCell3 = document.createElement("td")

        let comCell4 = document.createElement("td")
        
        let cellData = document.createTextNode(z.code + ":      " + z.name) //getting actual data from objects
        let cellData2 = document.createTextNode(z.credits.toString());
        let cellData3 = document.createTextNode(z.semesterCompleted);

        
        comTable?.appendChild(comRow); //appends all elements
        comRow?.appendChild(comCell);
        comRow?.appendChild(comCell2);
        comRow?.appendChild(comCell3);
        comCell?.appendChild(cellData);
        comCell2?.appendChild(cellData2);
        comCell3?.appendChild(cellData3);

        comRow.setAttribute('id','completedRow'); //setting ID for row alternate colors

        headingRow.setAttribute('id','headingRow'); //setting ID for row alternate colors
    }


    






















